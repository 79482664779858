<template>
  <div ref="pdf" />
</template>

<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pdfh5: undefined
    };
  },

  mounted() {
    this.renderView(this.url);
  },

  disabled() {
    this.pdfh5.destroy();
  },

  methods: {
    renderView(url) {
      this.pdfh5 = new Pdfh5(this.$refs["pdf"], {
        pdfurl: url,
        goto: 0,
        // scrollEnable: false // 是否允许pdf滚动
        zoomEnable: false // 是否允许pdf手势缩放
        // limit: 3
      });

      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
        console.log(`状态：${status}，信息：${msg}，耗时：${time}毫秒，总页数：${this.pdfh5.totalNum}`);
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
